import React from "react";
import "./App.css";
//import Display from "./Display";
//import ButtonPanel from "./ButtonPanel";
//import calculate from "../logic/calculate";
import Particles from "react-particles-js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //total: null,
      //next: null,
      //operation: null,
    };
  }

  //handleClick = buttonName => {
    //this.setState(calculate(this.state, buttonName));
  //};

  render() {
    return (
      <div className="component-app">
      <div className='space'>
        <Particles
            params={{
              "particles": {
                "number": {
                  "value": 160,
                  "density": {
                    "enable": true,
                    "value_area": 800
                  }
                },
                "color": {
                  "value": "#ffffff"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 5
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 1,
                  "random": true,
                  "anim": {
                    "enable": true,
                    "speed": 1,
                    "opacity_min": 0,
                    "sync": false
                  }
                },
                "size": {
                  "value": 2,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 3,
                    "size_min": 0.3,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": false,
                  "distance": 150,
                  "color": "#ffffff",
                  "opacity": 0.4,
                  "width": 1
                },
                "move": {
                  "enable": true,
                  "speed": 0.5,
                  "direction": "none",
                  "random": true,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 600
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "bubble"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 250,
                    "size": 0,
                    "duration": 2,
                    "opacity": 0,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 400,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              "retina_detect": true
            }} />
          </div>

          <div className='moon-container'>
            <div className='moon'>
              <img className='moon-img' src='images/element/moon.png' />

              <div className='logo'>
                <div className='xrp-svg'>
                  <img src='images/element/xrp_text.svg' />
                </div>
                <span>Innovations</span>
              </div>

            </div>
          </div>

          <div className='email'><a href='mailto:getintouch@xrpi.io'>getintouch@xrpi.io</a></div>
          <div className='intro-text'><span>Landing gear engaging, <br className="rwd-break"></br>exciting things coming this way...</span></div>
          <div className='twitter'>
              <a href='https://www.twitter.com/xrpinnovations'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" ></path></svg>
              </a>
          </div>

          <div className='xrp-transaction hide'>
            <div className='col'>
              <span className="helper"></span>
              <img src='images/element/logo.png' />
            </div>
            <div className='col'>
              <hr></hr>
              <div>01/01/2019</div>
              <div>Type: Payment</div>
              <div>From: rXXXXXX</div>
              <div>To: rXXXXXXX</div>
              <div>Amount: 589 XRP</div>
              <div><a href='https://bithomp.com/explorer/' target="_blank">Bithomp link</a></div>
              <hr></hr>
            </div>
          </div>
      </div>
    );
  }
}
export default App;
